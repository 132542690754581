<template>
  <page-box >
    <div slot="box" class="issued-manage">
      <!-- 数据表 -->
      <el-table
        border
        highlight-current-row
        :data="planList"
        class="issued-list"
        :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}"
        :cell-style="{color: '#0a0b1a'}"
        style="width: 100%"
      >
        <el-table-column width="50" align="center">
          <div slot="header" slot-scope="scope">
            <el-checkbox
              v-model="globelCheckedAll"
              @change="clickCheckAll(scope)"
              :indeterminate="isIndeterminateAll"
            ></el-checkbox>
          </div>
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.isChecked"
              :indeterminate="scope.row.isIndeterminate"
              @change="handleSelectionChange(scope.row)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="props">
            <!-- 展开内容表 -->
            <el-table
              :data="props.row.list"
              style="width: 100%"
              class="child-table"
              row-key="guid"
            >
              <el-table-column label="选择" width="120" align="center">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isChecked"
                    @change="clickCheckItemFn(props.row, scope.row)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="订单号">
              <template slot-scope="scope">{{ scope.row.planNumber }}</template>
            </el-table-column>
            <el-table-column prop="distributionTypeName" label="配送方式" ></el-table-column>
            <el-table-column prop="tractorNumber" label="车辆信息" ></el-table-column>
            <el-table-column prop="address" label="配送地" >
              <template slot-scope="scope">
                {{ scope.row.distributionType==1?name:scope.row.address }}
              </template>
            </el-table-column>
            <el-table-column prop="driverInfo" label="司机信息" >
              <template slot-scope="scope">
                <span>{{scope.row.driverInfo?scope.row.driverInfo.slice(0,-5):"--"}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="escortInfo" label="押运员信息" >
              <template slot-scope="scope">
                <span>{{scope.row.escortInfo?scope.row.escortInfo.slice(0,-5):"--"}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="arrivalDate" label="期望到站时间" ></el-table-column>
            <el-table-column prop="sourceName" label="液源" ></el-table-column>
            <el-table-column prop="listingPrice" label="挂牌价（元/吨）" ></el-table-column>
            <el-table-column prop="weight" label="预估总质量（吨）" ></el-table-column>
            <el-table-column prop="estimationMoney" label="预估总金额（元）" ></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="计划日期">
        <template slot-scope="scope">
            {{scope.row.time | timestampToTime}}
          </template>
      </el-table-column>
      <el-table-column prop="carNumber" label="所需车数" ></el-table-column>
      <el-table-column prop="weight" label="预估总质量（吨）" ></el-table-column>
      <el-table-column prop="price" label="预估总金额（元）" ></el-table-column>
      </el-table>
    </div>
  </page-box>
</template>
<script>
export default {
  name: "AuditDetailsList",
    props: {
      planList: {
        type: Array,
        default() {
          return []
        }
      },
      name: {
        type: String,
        default(){
          return []
        }
      }
    },
  data() {
    return {
      isIndeterminateAll: false,
      globelCheckedAll: false,
    }
  },
  mounted() {
    
  },
  methods: {
    updateRoleList() {
        this.$emit('plan-list');
      },
    // 手动更改全选按钮的状态
    updateCheckedAllBtnStatus(value) {
      
      // 如果是选了勾选
      if (value) {
        // 检查是否所有数据都手动勾选了
        let isAllChecked = this.planList.every(v => v.isChecked)
        if (isAllChecked) {
          this.globelCheckedAll = true
          this.isIndeterminateAll = false
        } else {
          this.isIndeterminateAll = true
        }
      } else {
        // 检查是否所有数据取消勾选了
        let isAllCancelChecked = this.planList.every(v => v.isChecked == false)
        if (isAllCancelChecked) {
          this.globelCheckedAll = false
          this.isIndeterminateAll = false
        } else {
          this.isIndeterminateAll = true
        }
      }
    },
    // 全选所有
    clickCheckAll(item) {
      // 此处console的item无效，仅为了eslint校验，而slot-scope="scope"又是必传
      this.planList = this.planList.map(val => {
        val.isChecked = this.globelCheckedAll
        val.list = val.list.map(i => {
          i.isChecked = this.globelCheckedAll
          return i
        })
        return val
      })
      this.updateCheckedAllBtnStatus(this.globelCheckedAll)
    },
    // 每行选择事件
    handleSelectionChange(val) {
      console.log(val);
      val.list = val.list.map(i => {
        i.isChecked = val.isChecked
        return i
      })
      val.isIndeterminate = false
      this.updateCheckedAllBtnStatus(val.isChecked)
    },

    // 每个小项选择事件-单选
    clickCheckItemFn(row, item) {
      console.log(row,item);
      // 如果是选了勾选
      if (item.isChecked) {
        this.isIndeterminateAll = true
        // 检查是否所有数据都手动勾选了
        let isAllChecked = row.list.every(v => v.isChecked)
        if (isAllChecked) {
          row.isChecked = true
          row.isIndeterminate = false
        } else {
          row.isIndeterminate = true
        }
      } else {
        // 检查是否所有数据取消勾选了
        let isAllCancelChecked = row.list.every(v => v.isChecked == false)
        if (isAllCancelChecked) {
          row.isChecked = false
          row.isIndeterminate = false
          this.isIndeterminateAll = false
        } else {
          row.isIndeterminate = true
        }
      }
    },
  }
}
</script>

<style scoped>
</style>