<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-15 15:30:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-13 16:26:41
-->
<template>
  <el-button type="primary"  @click="removeRoleById(list)">提交审批</el-button>
</template>

<script>
  import {successfulAuditRequest,auditFailureRequest} from 'network/plan'

  export default {
    name: "AuditModalSave",
    props: {
      list: {
        type: Array,
      }
    },
    methods: {
      // 根据ID删除用户
      removeRoleById(scope) {
          let newData = []
          for(let i = 0; i<scope.length;i++){
            for(let j=0;j<scope[i].list.length;j++){
              if(scope[i].list[j].isChecked==true){
                newData.push(scope[i].list[j])
              }
            }
          }
          if(newData.length==0){
          this.alertMessage('请先选择计划', 'error');
          }else{
            let ListId = []
            for(let i = 0;i<newData.length;i++){
              ListId.push(newData[i].planId)
            }
              this.$confirm('请核对账户余额及计划情况后选择是否通过此计划', '提示', {
                distinguishCancelAndClose: true,
                confirmButtonText: '通过',
                cancelButtonText: '不通过',
                type: 'warning'
              }).then(() => {
                successfulAuditRequest({
                  ids: ListId
                }).then(res => {
                  let result = res.data;
                  if (result.code !== 200) {
                    return this.alertMessage(result.msg, 'error');
                  }
                  this.alertMessage(result.msg, 'success');
                  this.$router.replace({path: '/pages/biz/gas_factory/plan/check'})
                  this.$emit('role-list');
                });
              }).catch((action) => {
                console.log(action);
                if(action=='cancel'){
                  auditFailureRequest({
                  ids: ListId
                }).then(res => {
                  let result = res.data;
                  if (result.code !== 200) {
                    return this.alertMessage(result.msg, 'error');
                  }
                  this.alertMessage(result.msg, 'success');
                  this.$router.replace({path: '/pages/biz/gas_factory/plan/check'})
                  this.$emit('role-list');
                });
                }
              });
          }
      }
    }
  }
</script>

<style scoped>
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}

</style>
