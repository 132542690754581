<template>
  <div style="height: 100%">
    <!-- 顶部面包屑导航 -->
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <breadcrumb-nav>
      <template v-slot:firstMenu>计划管理</template>
      <template v-slot:secondMenu>计划审核</template>
    </breadcrumb-nav>
      <div class="titleBox">查看详情</div>
      <div class="textBox">
        <span style="marginRight:50px">购液企业名称：{{ name }}</span>
        <span style="marginRight:50px">账户余额：{{balance}}元</span>
      </div>
      <div>
        <audit-modal-save :list='goodsList'/>
      </div>
      <!-- table表格区域 -->
      <audit-details-list :plan-list='goodsList' :name="name"/>
      <div style="float: right;">
          <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pageNum"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
      </div>
    </el-card>

    
  </div>
</template>

<script>
  import BreadcrumbNav from "../../common/BreadcrumbNav";
  import AuditDetailsList from './AuditDetailsList';
  import { getDetailListRequest } from "network/plan";
import AuditModal from './AuditModal.vue';
import AuditModalSave from './AuditModalSave.vue'

  export default {
    name: "AuditDetails",
    components: {
      BreadcrumbNav,
      AuditDetailsList,
        AuditModal,
        AuditModalSave
      
    },
    data() {
      return {
         radio1: '0',
        queryInfo: {
          query: "",
          pageNum: 1,
          pageSize: 10
        },
        formInline: {
          user: '',
          region: ''
        },
        oneList: [
          {
            carsNumber: 2,
            totalMass: 56.12,
            totalAmount: 113133.11,
          }
        ],
        goodsList: [],
        total: 0,
        name: '',
        balance: '',
      }
    },
    created() {
      this.getGoodsList();
    },
    methods: {
      getGoodsList() {
        console.log(this.$route.query.id);
        this.name = this.$route.query.name
        this.balance = this.$route.query.balance
        getDetailListRequest({
          ...this.queryInfo,
          enterId: this.$route.query.id
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }

          for(let i=0;i<result.data.list.length;i++){
            for(let x= 0;x<result.data.list[i].list.length;x++){
              result.data.list[i].list[x].isChecked = false
              result.data.list[i].list[x].isIndeterminate = false
            }
            result.data.list[i].carNumber = result.data.list[i].list.length
            result.data.list[i].weight = result.data.list[i].list.reduce((sum, e) => (sum*100 + Number(e.weight || 0)*100)/100, 0)
            result.data.list[i].price = result.data.list[i].list.reduce((sum, e) => (sum*100 + Number(e.estimationMoney || 0)*100)/100, 0)
            result.data.list[i].isChecked = false
            result.data.list[i].isIndeterminate = false
          }
          console.log(result.data.list);
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = newSize;
        this.getGoodsList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNum = newPage;
        this.getGoodsList();
      },

      // 跳转到添加商品的界面
      goAddPage() {
        this.$router.push('/add');
      },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList(){
        // this.getRolesList();
      }
    }
  }
</script>

<style scoped>
.titleBox{
  width: 100%;
  height: 46px;
  font-size: 20px;
  font-weight: normal;
  color: #0a0b1a;

}
.textBox{
  width: 100%;
  height: 66px;
  font-size: 14px;
  color: #0a0b1a;
  font-weight: normal;
  line-height: 66px;
  
}
.numberBox{
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.settlement{
  width: 528px;
  height: 150px;
  background: #f5f7fa;
  border-radius: 4px;
  opacity: 1;
}
.settlement_title{
  margin-left: 20px;
  margin-top: 23px;
  font-size: 20px;
  font-weight: normal;
  color: #0a0b1a;
}
.settlement_number{
  margin-left: 20px;
  margin-top: 24px;
  color: #54555F;

}

.radioBox{
  margin-bottom: 15px;
}
.buttonBox{
  display: flex;
}
</style>
