<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-28 14:47:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-22 09:36:54
-->
<template>
<div>
    <el-row>
      <el-col>
        <el-button type="primary" @click="priceRecord(list)">提交审批</el-button>
      </el-col>
    </el-row>

  <el-dialog
            title="价格记录"
            :visible.sync="setRightDialogVisible"
            width="70%">
            <!-- 订单总数列表 -->
      <el-table highlight-current-row :data="TotalList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
        <el-table-column label="总车数" prop="carNumber"></el-table-column>
        <el-table-column prop="shipmentWeight" label="装车量（吨）" ></el-table-column>
        <el-table-column prop="weight" label="结算量（吨）" ></el-table-column>
        <el-table-column prop="totalAmount" label="结算总金额（元）" ></el-table-column>
      </el-table>

      <!-- 选择订单详情列表 -->
      <el-table highlight-current-row :data="priceList" border >
        <el-table-column label="计划日期" prop="planDate"></el-table-column>
        <el-table-column label="订单号">
          <template slot-scope="scope">{{ scope.row.orderNumber }}</template>
        </el-table-column>
        <el-table-column prop="distributionType" label="配送方式" ></el-table-column>
        <el-table-column prop="tractorNumber" label="车辆信息" ></el-table-column>
        <el-table-column prop="address" label="配送地" ></el-table-column>
        <el-table-column prop="driverInfo" label="司机信息" ></el-table-column>
        <el-table-column prop="escortInfo" label="押运员信息" ></el-table-column>
        <el-table-column prop="auditDate" label="期望到站时间" ></el-table-column>
        <el-table-column prop="sourceName" label="液源" ></el-table-column>
        <el-table-column prop="listingPrice" label="挂牌价（元/吨）" ></el-table-column>
        <el-table-column  label="预估总质量（元）">
           <template slot-scope="scope">{{ scope.row.weight }}</template>
        </el-table-column>
        <el-table-column  label="结算总金额（元）">
           <template slot-scope="scope">{{ scope.row.estimationMoney }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRightDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveButton">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
import {infoPlan} from 'network/plan'

export default {
  name: "AuditModal",
  props: {
    list: {
      type: Array,
    }
  },
  data() {
    return {
      property: 'value',
      setRightDialogVisible: false,  
      priceList: [],
      TotalList: [],
      // formInline: {
      //   region: ''
      // }      
    };
  },
  methods: {
    priceRecord(scope){
      this.setRightDialogVisible = true
      let newData = []
      for(let i = 0; i<scope.length;i++){
        for(let j=0;j<scope[i].list.length;j++){
          if(scope[i].list[j].isChecked==true){
            newData.push(scope[i].list[j])
          }
        }
      }
      
      this.priceList = newData
      let newInfo = [{
        carNumber: newData.length,
        shipmentWeight: 0,
        weight: 0,
        // totalAmount: newData.reduce((c, R) => c + R.orderBill.totalAmount, 0)
        totalAmount: 0
      }]
      this.TotalList = newInfo
    },
    saveButton(){
      let ListId = []
      for(let i = 0;i<this.priceList.length;i++){
        ListId.push(this.priceList[i].planId)
      }
      console.log(ListId);
      infoPlan({
        enterId: ListId
      }).then(res=> {
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage(result.msg, 'error');
          }
          this.setRightDialogVisible = false
          this.$router.replace({path: '/pages/biz/gas_factory/finance/reconciliation'})
      })
    }
  }
}
</script>
<style scoped>
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
</style>
